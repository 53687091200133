import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import { InboundDocumentContextProvider } from "src/components/Contexts/InboundDocumentContext";
import InboundDocumentFetcher from "src/components/Pages/DocumentInbound/InboundDocumentFetcher";
import InboundDocumentHeader from "src/components/Pages/DocumentInbound/InboundDocumentHeader";
import { InboundDocumentPageRequest } from "src/api/interfaces/inbound_document";
import InboundDocumentNavbar from "src/components/Pages/DocumentInbound/InboundDocumentNavbar";
import styles from "src/styles.module.scss";
import AICLayout from "src/components/AICLayout/AICLayout";
import { DocumentComplianceContextProvider } from "src/components/Contexts/DocumentComplianceContext";
import { ValidationResultsContextProvider } from "src/components/Contexts/ValidationResultsContext";

const InboundDocument: FC<InboundDocumentProps> = ({ match }) => (
  <AICLayout
    disableCountryChange
    fixedCountry={match.params.countryCode}
    disableUrlSearchParamsUpdate
  >
    <InboundDocumentContextProvider>
      <InboundDocumentFetcher
        countryCode={match.params.countryCode}
        documentId={match.params.documentId}
        documentType={match.params.documentType}
      />
      <DocumentComplianceContextProvider>
        <ValidationResultsContextProvider>
          <div className={styles.ma_10}>
            <InboundDocumentHeader />
            <InboundDocumentNavbar
              inboundDocumentTab={match.params.inboundDocumentTab}
            />
          </div>
        </ValidationResultsContextProvider>
      </DocumentComplianceContextProvider>
    </InboundDocumentContextProvider>
  </AICLayout>
);

export default InboundDocument;

type InboundDocumentProps = RouteComponentProps<InboundDocumentPageRequest>;
